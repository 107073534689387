.site{
    margin-left: 5px;
}

iframe{
    width: 100%;
    height: 300px;
    border: 1px solid #000;
    border-bottom: 2px solid #000;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);
    margin-top: 25px;
}

.contact__img{
    margin-bottom: 50px;
}

.contact__align {
    text-align: center;
    margin-top: -145px;
    margin-bottom: 50px;

    .col-33 {
        flex: 0 0 auto;
        width: calc(33.33% - 30px);
        margin-left: 15px;
        margin-right: 15px;
        box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        background-color: white;
        padding: 20px 20px;
    }

    svg {
        font-size: 28px;
        color: #9ecb3b;
    }

    h3 {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 32px;
        font-weight: 900;
        line-height: 38px;
    }

    p {
        color: #6c7689;
        font-weight: 400;
        margin-bottom: 0px;

        a{
            display:block;
            margin-bottom: 5px;
        }
    }

    @media only screen and (max-width: 998px) {
        gap: 20px;
    }    
}

.contact__mobile {
    @media only screen and (max-width: 800px) {
        width: calc(100% - 30px) !important;
    }
}

h2 {
    font-size: 22px;
    margin: 0;
}

h1 {
    margin: 0;
    font-size: 54px;
    line-height: 60px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.divider {
    background-color: #82a438;
    height: 2px;
    display: inline-block;
    width: 50px;
    top: -14px;
    position: relative;
}

input,
textarea {
    width: 100%;
    margin-bottom: 45px;
    padding: 10px 15px;
    border-bottom: 1px solid rgb(140, 218, 140);
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 4px;
    background-color: #e1efc4;
    outline: none;
}

textarea {
    resize: none;
    height: 200px;
}

.flex {
    display: flex;
    gap: 40px;

    @media only screen and (max-width: 998px) {
        display: block;
    }
}
