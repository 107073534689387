@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Satisfy&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
  background-color: #f4ffd566;

  a {
    text-decoration: none;
  }

  .button {
    padding: 12px 30px;
    border: none;
    font-size: 16px;
    color: #fff;
    border-radius: 5px;
    background-color: #82a438;
    cursor: pointer;
    transition: 0.7s;
    font-weight: 500;
    font-family: "Manrope", sans-serif;

    @media only screen and (max-width: 550px) {
      font-size: 13px;
      padding: 10px 25px;
    }

    svg {
      margin-bottom: -3px;
      transition: 0.5s;
      margin-left: 5px;
    }

    &:hover {
      background-color: #9ecb3b;

      svg {
        transform: translateX(10px);
      }
    }
  }

  .right {
    float: right;
  }

  .logo {
    width: 350px;
    margin-top: 5px;

    @media only screen and (max-width: 600px) {
      width: 300px;
    }

    @media only screen and (max-width: 450px) {
      width: 230px;
    }
  }

  .wrapper-container,
  .container {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1450px) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .wrapper-container {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .row {
    width: calc(100% + 19px);
    max-width: unset;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    .col-100 {
      flex: 0 0 auto;
      width: calc(100% - 30px);
      margin-left: 15px;
      margin-right: 15px;

      @media (max-width: 998px) {
        flex: 0 0 auto;
        width: calc(100% - 30px);
      }

      * {
        max-width: 100%;
      }
    }

    .col-75 {
      flex: 0 0 auto;
      width: calc(75% - 30px);
      margin-left: 15px;
      margin-right: 15px;

      @media (max-width: 998px) {
        flex: 0 0 auto;
        width: calc(100% - 30px);
      }

      * {
        max-width: 100%;
      }
    }

    .col-60 {
      flex: 0 0 auto;
      width: calc(60% - 30px);
      margin-left: 15px;
      margin-right: 15px;

      @media (max-width: 998px) {
        flex: 0 0 auto;
        width: calc(100% - 30px);
      }

      * {
        max-width: 100%;
      }
    }

    .col-50 {
      flex: 0 0 auto;
      width: calc(50% - 30px);
      margin-left: 15px;
      margin-right: 15px;

      @media (max-width: 998px) {
        flex: 0 0 auto;
        width: calc(100% - 30px);
      }

      * {
        max-width: 100%;
      }
    }

    .col-40 {
      flex: 0 0 auto;
      width: calc(40% - 30px);
      margin-left: 15px;
      margin-right: 15px;

      @media (max-width: 998px) {
        flex: 0 0 auto;
        width: calc(100% - 30px);
      }

      * {
        max-width: 100%;
      }
    }

    .col-33 {
      flex: 0 0 auto;
      width: calc(33.33% - 30px);
      margin-left: 15px;
      margin-right: 15px;

      @media (max-width: 998px) {
        flex: 0 0 auto;
        width: calc(100% - 30px);
      }

      @media (max-width: 800px) {
        flex: 0 0 auto;
        width: calc(100% - 30px);
      }

      * {
        max-width: 100%;
      }
    }

    .col-25 {
      flex: 0 0 auto;
      width: calc(25% - 30px);
      margin-left: 15px;
      margin-right: 15px;

      @media (max-width: 998px) {
        flex: 0 0 auto;
        width: calc(50% - 30px);
      }

      @media (max-width: 800px) {
        flex: 0 0 auto;
        width: calc(100% - 30px);
      }
    }

    .col-20 {
      flex: 0 0 auto;
      width: calc(20% - 30px);
      margin-left: 15px;
      margin-right: 15px;

      @media (max-width: 998px) {
        flex: 0 0 auto;
        width: calc(50% - 30px);
        margin-bottom: 20px;
      }

      @media (max-width: 800px) {
        flex: 0 0 auto;
        width: calc(100% - 30px);
      }
    }

    .col-30 {
      flex: 0 0 auto;
      width: calc(30% - 30px);
      margin-left: 15px;
      margin-right: 15px;

      @media (max-width: 998px) {
        flex: 0 0 auto;
        width: calc(50% - 30px);
      }

      @media (max-width: 800px) {
        flex: 0 0 auto;
        width: calc(100% - 30px);
      }
    }
  }

  .mb-90 {
    margin-bottom: 90px;

    @media only screen and (max-width: 998px) {
      margin-bottom: 40px;
    }
  }

  .mt-40 {
    margin-top: 40px;
  }

  .mb-60 {
    margin-bottom: 60px;
  }

  .bg-white {
    background-color: #fff;
  }

  ::selection {
    background-color: #9ecb3b;
    color: #fff;
  }
}

.bg__color {
  background-color: #82a438;

  h1 {
    text-align: center;
    padding: 150px 0 75px 0;
    font-size: 44px;
    color: white;

    @media only screen and (max-width: 800px) {
      font-size: 42px;
      // padding: 110px 0;
    }

    @media only screen and (max-width: 560px) {
      font-size: 28px;
      padding-top: 90px;
      padding-bottom: 40px;
    }
  }
}

.bottom__map {
  width: 100%;
  margin-top: 70px;
  margin-bottom: 0;
  aspect-ratio: 16 / 4;
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

.text-center {
  text-align: center;
}