.our-company {
    .wrapper-container {
        max-width: 980px;
        padding-top: 25px;
        padding-bottom: 25px;
        margin-left: auto;
        margin-right: auto;

        .activity {
            display: flow-root;
            margin-top: 50px;
            margin-bottom: 50px;

            .activity__paragraph {
                max-width: 700px;
                float: left;
                display: inline-flex;
                gap: 10px;
                margin-bottom: 50px;

                .flex {
                    display: flex;

                    .company__content {
                        h2 {
                            @media only screen and (max-width: 850px) {
                                font-size: 22px;
                            }
                        }

                        p {
                            @media only screen and (max-width: 850px) {
                                font-size: 16px;
                            }
                        }
                    }

                    span {
                        width: 2px;
                        background: #82a438;
                    }
                }

                h2 {
                    font-size: 24px;
                }

                p {
                    font-size: 18px;
                    margin: 0;
                }

                &:nth-child(even) {
                    float: right;
                    text-align: right;
                }
            }
        }

        @media only screen and (max-width: 1020px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}
