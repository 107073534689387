.home__title {
    text-align: center;
    margin-bottom: 30px;

    @media only screen and (max-width: 550px) {
        margin-bottom: 40px;
    }

    span {
        color: #9ecb3b;
        font-size: 22px;
        line-height: 1;
        letter-spacing: 2px;
        font-family: "Satisfy", cursive;

        @media only screen and (max-width: 550px) {
            font-size: 16px;
        }
    }

    h1 {
        font-size: 34px;

        @media only screen and (max-width: 550px) {
            font-size: 28px;
            line-height: 28px;
        }
    }
}

.above__button {
    margin-bottom: 45px;
}

.card {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;

    .card-box {
        padding: 20px;
        border-radius: 5px;
        transition: 0.3s;
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 30px 0px;

        .card-info {
            display: block;
            align-items: flex-start;
            column-gap: 12px;
            margin-bottom: 12px;

            img {
                width: 100%;
            }

            .card-title {
                text-align: center;
                margin-top: 10px;
            }

            .product__title {
                font-size: 22px;
                line-height: 26px;
                color: #000;
                margin-bottom: 4px;
                margin-top: 30px;

                @media only screen and (max-width: 1100px) {
                    font-size: 22px;
                }
            }

            p {
                margin-top: 20px;
                font-weight: 500;
                color: #6c7689;
                margin-bottom: 0px;
                font-size: 14px;

                @media only screen and (max-width: 1100px) {
                    font-size: 13px;
                }
            }

            h1 {
                line-height: auto;
                margin: 0;
            }

            .image-box {
                position: relative;
                overflow: hidden;
                width: calc(100% + 50px);
                margin-left: -25px;
                border-radius: 6px;
                margin-top: -25px;
                height: 250px;
                box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.15);

                img {
                    max-width: 100%;
                    transition: all 0.3s;
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                &:hover img {
                    transform: scale(1.1);
                }
            }

            .card__height {
                min-height: 160px;

                @media only screen and (max-width: 630px) {
                    min-height: auto;
                }
            }
        }
    }

    @media only screen and (max-width: 1320px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 940px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 630px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.highlighted {
    visibility: visible;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    .content {
        padding: 30px 60px 50px 50px;
        border-radius: 20px;
        -webkit-box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
        margin-top: 45px;
        background-color: white;
        min-height: 250px;

        .title {
            margin: 0;
            font-size: 26px;
            line-height: 44px;
            font-weight: 600;
            font-family: "Poppins", sans-serif;

            @media only screen and (max-width: 394px) {
                line-height: 30px;
            }
        }
    }

    .thumbnail{
        margin-inline-start: -40px;
    }
}

.home {
    .wrapper-container {
        padding-top: 75px;
        padding-bottom: 50px;

        .thumbnail {
            img {
                border-radius: 20px;
                width: 500px;
                object-fit: cover;
                margin-bottom: -30px;
                max-height: 300px;
                height: 285px;
                box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.25);
            }

            @media only screen and (max-width: 1100px) {
                display: none;
            }
        }

        .highlighted-brands {
            margin-top: -25px;
            overflow-x: hidden;

            div {
                width: calc(25% - 38px);
                display: inline-block;
                background-color: white;
                border-radius: 5px;
                margin: 25px;
                background: #fff;
                box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 30px 0px;
                text-align: center;
                padding: 25px;
                height: 160px;
                vertical-align: top;

                &:first-child {
                    margin-left: 0;

                    @media only screen and (max-width: 1150px) {
                        margin-left: 25px;
                    }

                    @media only screen and (max-width: 580px) {
                        margin-left: 0;
                    }
                }

                &:last-child {
                    margin-right: 0;

                    @media only screen and (max-width: 1150px) {
                        margin-right: 25px;
                    }
                }

                &.maestro img {
                    height: 100px;
                    width: unset;
                    margin-top: 3px;
                }

                &.pure-land img {
                    height: 75px;
                    margin-top: 15px;
                    width: unset;
                }

                &.florin img {
                    height: 75px;
                    margin-top: 20px;
                    width: unset;
                }

                &.soul img {
                    height: 90px;
                    margin-top: 10px;
                    width: unset;
                }

                @media only screen and (max-width: 1150px) {
                    width: calc(50% - 50px);
                }

                @media only screen and (max-width: 580px) {
                    width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }

        @media only screen and (max-width: 1100px) {
            padding-top: 15px;
        }
    }

    .section-title {
        margin-top: 25px;

        h1 {
            box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 30px 0px;
            text-align: center;
            font-size: 28px;
            font-weight: 500;
            padding-top: 30px;
            padding-bottom: 30px;
            background-color: white;

            &:after {
                content: none;
            }
        }

        @media only screen and (max-width: 1100px) {
            margin-bottom: 60px;
        }
    }

    .brands {
        text-align: center;

        img {
            width: calc(200px);
            display: inline-block;
            background-color: white;
            border-radius: 5px;
            margin: 25px;
            background: #fff;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 30px 0px;
            text-align: center;
            padding: 25px;
            height: 100px;
            vertical-align: top;
            object-fit: contain;
        }
    }
}