.row {
    .wholesale__img {
        width: 100%;
        height: 500px;
        object-fit: cover;
        border-radius: 8px;
    }

    .wholesale__box {
        height: 100%;
        display: flex;
        align-items: center;

        h2 {
            @media only screen and (max-width: 800px) {
                font-size: 16px;
                line-height: 16px;
            }
        }

        h1 {
            @media only screen and (max-width: 800px) {
                font-size: 42px;
                line-height: 42px;
            }

            @media only screen and (max-width: 560px) {
                font-size: 28px;
                line-height: 28px;
            }
        }

        p {
            @media only screen and (max-width: 800px) {
                font-size: 14px;
            }
        }
    }
}

.wholesale {
    p{
        font-size: 22px;
    }

    &:nth-child(2) {
        flex-direction: row-reverse;
    }

    &:not(:first-child) {
        margin-top: -80px;
    }

    @media only screen and (max-width: 998px) {
        margin-top: 0 !important;
    }
}
