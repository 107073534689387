header {
    position: fixed;
    z-index: 1;
    width: 100%;
    background-color: transparent;
    top: 0;
    transition: 0.3s;
    z-index: 2;
    background-color: #fff;
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid #82a438;

    .main-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;

        nav {
            .nav__links {
                list-style: none;
                display: flex;
                line-height: 80px;
                gap: 40px;

                li {
                    a {
                        transition: color 0.3s ease 0s;
                        font-weight: 500;
                        color: #1c2137;
                        text-decoration: none;
                        font-size: 16px;
                        text-transform: uppercase;
                        position: relative;

                        &:hover {
                            color: #688b1a;
                        }

                        svg {
                            margin-left: 5px;
                            box-sizing: border-box;
                        }
                    }

                    .language {
                        border-left: 2px solid #878a8b;
                        padding-left: 20px;
                        margin-left: -20px;
                    }
                }

                a.active {
                    color: #a3ce46;
                }

                @media only screen and (max-width: 1360px) {
                    display: none;
                }
            }

            .toggle__btn {
                display: none;
                padding: 25px;
                font-size: 30px;
                cursor: pointer;
                position: absolute;
                z-index: 1;
                right: 0;
                top: 0;
                justify-content: center;
                align-items: center;
                line-height: 15px;

                @media only screen and (max-width: 1360px) {
                    display: flex;
                }
            }

            .mobile__dropdown {
                position: absolute;
                overflow: hidden;
                height: 0;
                transition: 0.3s;
                background-color: #fff;
                border-radius: 15px;
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
                right: 20px;
                width: 300px;
                top: 85px;

                ul {
                    margin: 15px 0;

                    li {
                        a {
                            color: #1c2137;
                            width: 100%;
                            display: block;
                            padding: 15px;
                            box-sizing: border-box;
                            transition: 0.3s;
                            text-transform: uppercase;
                        }

                        &:active,
                        &:hover {
                            background-color: #82a438;

                            a {
                                color: #fff;
                            }
                        }
                    }
                }

                @media only screen and (max-width: 420px) {
                    left: 20px;
                    width: initial;
                    right: 20px;
                }

                @media only screen and (min-width: 1360px) {
                    display: none;
                }
            }

            .open-en {
                height: 340px;
            }

            .open {
                height: 390px;
            }
        }
    }
}

.sticky {
    position: fixed;
    top: 0;
    background-color: #fff;
    transition: 0.3s;
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.1);
    z-index: 10;
}