.products {
    .wrapper-container {
        padding-top: 0;

        .buttons {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }

    .card .card-box .card-info h1.product__title {
        margin-top: 30px;
    }

    .card .card-box .card-info p {
        margin-bottom: 0px;
    }

    .buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .button {
            padding: 10px 15px;
            background-color: #82a438;
            display: flex;
            justify-content: center;
            align-items: center;

            &.active,
            &:hover {
                background-color: #4f9566;
                color: white !important;
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }

        @media only screen and (max-width: 891px) {
            scroll-snap-type: x proximity;
            scroll-behavior: smooth;
            overflow-x: scroll;
            white-space: nowrap;
            flex-wrap: initial;
        }
    }

    .variant-container {
        padding: 20px;
        border-radius: 5px;
        transition: 0.3s;
        display: flex;
        margin-bottom: 75px;

        &:last-child {
            margin-bottom: 0;
        }

        div.image {
            box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 30px 0px;
            max-height: 400px;

            img {
                width: 400px;
                height: 100%;
                object-fit: cover;
                transition: all 0.3s;
                border-radius: 3px;

                @media only screen and (max-width: 760px) {
                    width: 100%;
                }
            }

            &:hover img {
                transform: scale(1.1);

                @media only screen and (max-width: 760px) {
                    transform: none;
                }
            }

            @media only screen and (max-width: 760px) {
                box-shadow: none;
                margin-bottom: 15px;
            }
        }

        div.list {
            background: #fff;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 30px 0px;
            padding-left: 20px;
            padding-right: 20px;
            margin-left: 50px;
            flex-grow: 1;

            div {
                width: 100%;
                display: inline-block;
                padding-bottom: 12px;
                padding-top: 12px;
                border-bottom: 1px solid #efefef;

                &:last-child {
                    border: none;
                }
            }

            @media only screen and (max-width: 760px) {
                margin-left: 0;
            }
        }

        @media only screen and (max-width: 760px) {
            display: grid;
            padding: 0;
        }
    }
}