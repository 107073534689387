.hero {
    background-color: #82a438;
    padding-top: 117px;
    overflow: hidden;
    color: white;
    height: 500px;

    .row {
        @media only screen and (max-width: 998px) {
            gap: 50px;
        }

        .text {
            flex: 0 0 auto;
            width: calc(40%);

            @media only screen and (max-width: 998px) {
                margin-top: 40px;
                width: 100%;
            }
        }

        .imgs {
            flex: 0 0 auto;
            width: calc(60% - 15px);

            @media only screen and (max-width: 998px) {
                display: none;
            }
        }

        .vertical__text {
            font-family: "Poppins", sans-serif;
            font-size: 80px;
            line-height: 0px;
            font-weight: 600;
            text-transform: capitalize;
            color: transparent;
            z-index: 1;
            -webkit-text-stroke: 1px #ffffff;
            opacity: 0.5;
            transform: rotate(-90deg);
            position: absolute;
            left: 40px;
            top: 280px;

            @media only screen and (max-width: 998px) {
                display: none;
            }
        }

        .hero__left {
            margin-left: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            z-index: 1;
            position: relative;

            h1 {
                font-size: 44px;
                font-weight: 400;
                font-family: "Poppins", sans-serif;
                margin-top: -30px;
                margin-bottom: 28px;
                line-height: 48px;

                span {
                    display: inline-block;
                    font-size: 20px;
                    margin-top: 0px;
                    margin-left: 15px;
                    position: absolute;

                    @media only screen and (max-width: 1416px) {
                        margin-left: 0;
                    }

                    @media only screen and (max-width: 998px) {
                        width: 100%;
                        position: initial;
                    }
                }

                br{
                    display: none;

                    @media only screen and (max-width: 1416px) {
                        display: block;
                    }
                }

                @media only screen and (max-width: 550px) {
                    font-size: 48px;
                    line-height: 56px;
                }
            }

            span {
                font-size: 18px;
                display: block;
            }

            a.button {
                padding: 10px 30px;
                border: none;
                font-size: 16px;
                color: #fff;
                border-radius: 5px;
                border: 1px solid white;
                cursor: pointer;
                transition: 0.7s;
                font-weight: 500;
                font-family: "Manrope", sans-serif;
                background-color: transparent;
                margin-top: 50px;
                display: inline-block;
            }

            p {
                font-size: 16px;
                color: #6c768a;
                margin-top: 0;
                margin-bottom: 32px;

                @media only screen and (max-width: 550px) {
                    font-size: 12px;
                }
            }

            @media only screen and (max-width: 998px) {
                margin-left: 0;
                max-width: 450px !important;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
            }
        }

        .hero__right {
            text-align: center;
            margin-top: -35px;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                top: -25px;
                height: 150%;
                width: 120px;
                left: -70px;
                background-color: #82a438;
                transform: rotate(10deg);
            }

            button {
                &:hover {
                    background: none !important;
                }
            }

            .carousel-status {
                display: none;
            }


            li.slide {
                width: 843px;

                a {
                    div {
                        width: 843px;

                        img {
                            width: 100%;
                            object-fit: cover;
                            height: 420px;
                        }
                    }
                }
            }
        }
    }

    .carousel-slider {
        .carousel-custom-arrow {
            color: rgb(255, 255, 255);
            background: none;
            border: none;
            cursor: pointer;
            width: 40px;
            height: 40px;
            display: inline-block;
            background-color: #82a438;
            border-radius: 50%;
            top: calc(50% - 30px);
        }

        .carousel-prev-arrow {
            position: absolute;
            z-index: 1;
            left: 15px;
        }

        button.carousel-next-arrow {
            position: absolute;
            z-index: 1;
            right: 15px;

            &:hover {
                background-color: #4f9566 !important;
            }

            span {
                margin-left: 4px;
                margin-top: 2px;
            }
        }
    }

    .carousel .control-dots {
        margin-bottom: 20px;

        .dot {
            box-shadow: none;
            border: 2px solid #82a438;
            height: 12px;
            width: 12px;
        }
    }

    @media only screen and (max-width: 998px) {
        padding-top: 140px;
    }

    @media only screen and (max-width: 550px) {
        padding-top: 120px !important;
    }

    @media only screen and (max-width: 421px) {
        padding-top: 110px !important;
    }
}

.slide-left-enter {
    transform: translateX(-100%);
    opacity: 0;
}

.slide-left-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 1000ms, opacity 1000ms;
}

.slide-left-exit {
    transform: translateX(0);
    opacity: 1;
}

.slide-left-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 1000ms, opacity 1000ms;
}

.slide-right-enter {
    transform: translateX(100%);
    opacity: 0;
}

.slide-right-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 1000ms, opacity 1000ms;
}

.slide-right-exit {
    transform: translateX(0);
    opacity: 1;
}

.slide-right-exit-active {
    transform: translateX(100%);
    opacity: 0;
    transition: transform 1000ms, opacity 1000ms;
}