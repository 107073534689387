.packinging {
    p {
        font-size: 22px;
    }

    ul {
        li {
            font-size: 20px;
            list-style-type: disc;
            margin-left: 25px;

            @media only screen and (max-width: 550px) {
                font-size: 16px;
            }
        }
    }

    .packaging__image{
        object-fit: cover;
        max-height: 600px;
        border-radius: 8px;
        
        @media only screen and (max-width: 998px) {
            width: 100%;
        }
    }

    .packaging__box {
        height: 100%;
        display: flex;
        align-items: center;

        .border {
            color: #525252;
            font-size: 18px;
            font-weight: 700;
            font-style: italic;
            padding-left: 25px;
            border-left: 2px solid #9ecb3b;

            @media only screen and (max-width: 998px) {
                font-size: 16px
            }

            @media only screen and (max-width: 550px) {
                font-size: 15px;
            }
        }

        p {
            font-weight: 400;
            color: #525252;

            @media only screen and (max-width: 998px) {
                font-size: 16px
            }

            @media only screen and (max-width: 550px) {
                font-size: 15px;
            }
        }

        h1 {
            @media only screen and (max-width: 998px) {
                font-size: 40px;
                line-height: 40px;
            }

            @media only screen and (max-width: 550px) {
                font-size: 30px;
                line-height: 30px;
            }

            br {
                @media only screen and (max-width: 998px) {
                    display: none;
                }
            }
        }
    }
}

