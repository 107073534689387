.copy__logos {
    display: flex;
    justify-content: space-between;

    .brands {
        display: inline-block;
        text-align: right;
        margin-top: -18px;

        h5 {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 36px;
            text-align: center;
        }

        img {
            height: 45px;
            display: inline-block;
            border-radius: 5px;
            margin: 7px;
            text-align: center;
            padding: 5px;
            vertical-align: top;
            object-fit: contain;
        }

        @media only screen and (max-width: 1360px) {
            text-align: center;
            margin-top: 15px;
        }
    }

    @media only screen and (max-width: 1360px) {
        display: block;
    }
}

footer {
    background-color: #e1efc4;
    padding-top: 90px;

    .logo {
        width: 300px;

        @media only screen and (max-width: 1300px) {
            width: 230px;
        }
    }

    @media only screen and (max-width: 1100px) {
        padding-top: 40px;
    }

    .footer__top {
        border-bottom: 2px solid #d4e3b5;
        padding-bottom: 50px;

        img {
            margin-bottom: 10px;
        }

        p {
            font-size: 16px;
            margin-bottom: 32px;
            font-weight: 400;
            color: #6c768a;

            @media only screen and (max-width: 1300px) {
                font-size: 14px;
            }
        }

        h5 {
            margin-top: 0;
            font-size: 23px;
            margin-bottom: 10px;

            @media only screen and (max-width: 1300px) {
                font-size: 21px;
            }
        }

        .social__icons {
            display: flex;
            gap: 12px;

            a {
                color: white;

                li {
                    line-height: 12px;
                    padding: 14px;
                    background-color: #9ecb3b;
                    border-radius: 5px;

                    &:hover {
                        background-color: #82a438;
                    }
                }

                &:visited {
                    color: white;
                }
            }
        }

        ul {
            li {
                padding: 8px 0;
                color: #6c768a;

                a {
                    color: #6c768a;

                    @media only screen and (max-width: 1300px) {
                        font-size: 14px;
                    }

                    svg {
                        margin-right: 5px;
                        margin-bottom: -2px;
                        font-size: 20px;

                        @media only screen and (max-width: 1300px) {
                            font-size: 16px;
                        }
                    }

                    &:hover {
                        color: #9ecb3b !important;
                    }

                    &:visited {
                        color: #6c768a;
                    }
                }
            }
        }

        .quick__contact {

            a,
            li {
                display: flex;

                svg {
                    margin-top: 2px;
                    margin-right: 15px;
                    margin-bottom: -2px;
                    color: #82a438;
                    font-size: initial;
                }

                &:hover {
                    color: #6c768a !important;
                }
            }
        }

        @media only screen and (max-width: 1100px) {
            padding-bottom: 15px;
        }
    }

    .footer__bottom {
        padding-top: 30px;
        padding-bottom: 30px;

        span {
            font-size: 14px;
            color: #6c768a;
        }
    }
}

.fix-image {
    width: 350px;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1;

    @media only screen and (max-width: 600px) {
        width: 300px;
    }
}